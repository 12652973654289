import styled from 'styled-components';

const TableContainer = styled.div`
  margin: 15px 15px;
  width: 90%;
  height: 730px;
  overflow-y: scroll;

  @media (max-width: 861px) {
    font-size: 12px;
  }

  @media (max-width: 1064px) {
    font-size: 14px;
  }

  .iconHolder > td {
    border: none;
    padding: 0;
  }
`;

const SearchContainer = styled.div`
    width: 90%;
    margin: 15px 15px 15px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 505px) {
      display: block;
      margin-top: 10px;
    }
`;

const SearchButtonContainer = styled.div`
    display: flex;

    @media (max-width: 505px) {
      margin-top: 10px;
    }
`;

const ToastContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`;

const ToastBody = styled.div`
  background-color: slategray;
  color: white;
  font-size: 20px;
  padding: 10px;
`;

export {
  TableContainer,
  SearchButtonContainer,
  SearchContainer,
  ToastContainer,
  ToastBody,
};
