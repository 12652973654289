import React, { useEffect, useState } from 'react';
import {
  boolean,
} from 'prop-types';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Toast,
} from 'react-bootstrap';
import {
  TableContainer,
  SearchButtonContainer,
  SearchContainer,
  ToastContainer,
  ToastBody,
} from './index.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import coachSampleCSV from '../../static/files/coach_sample_csv.csv';

function Employees({
  darkMode,
}) {
  const authToken = sessionStorage.getItem('authToken');
  const COMPANYID = sessionStorage.getItem('company_id');
  const [loading, setLoading] = useState(true);
  const [shownEmployees, setShownEmployees] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    job_role: 0,
    active: false,
  });
  const [noModalChange, setNoModalChange] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteModalLoading, setInviteModalLoading] = useState(false);
  const [inviteModalData, setInviteModalData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    job_role: 1,
  });
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [showGoodToast, setShowGoodToast] = useState(false);
  const [showBadToast, setShowBadToast] = useState(false);
  const [showEmailTakenToast, setShowEmailTakenToast] = useState(false);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/employees/`;
      const emps = [];
      while (url) {
        const {
          data,
        // eslint-disable-next-line no-await-in-loop
        } = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
        emps.push(...data.results);
        url = data.next;
      }

      const newE = [];
      for (let i = 0; i < emps.length; i += 1) {
        const {
          uuid,
          first_name,
          last_name,
          email,
          job_role,
          active,
        } = emps[i];
        newE.push({
          id: uuid,
          first_name,
          last_name,
          email,
          job_role,
          active,
        });
      }
      setAllEmployees(newE);
      setShownEmployees(newE);
    } catch (e) {
      showBadToast(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleInputChange = (event) => {
    let val = event.target.value.toLowerCase();
    setInputValue(val);
    if (val === '') {
      setShownEmployees(allEmployees);
    } else {
      val = val.toLowerCase();
      setShownEmployees(allEmployees.filter(e => e.email.toLowerCase().indexOf(val) !== -1
      || e.first_name.toLowerCase().indexOf(val) !== -1
      || e.last_name.toLowerCase().indexOf(val) !== -1));
    }
  };

  const handleClose = () => {
    setShow(false);
    setNoModalChange(true);
  };
  const handleShow = () => setShow(true);

  const handleInviteModalClose = () => {
    setValidated(false);
    setShowInviteModal(false);
  };
  const handleInviteModalShow = () => setShowInviteModal(true);

  const handleCSVModalShow = () => setShowCSVModal(true);
  const handleCSVModalClose = () => setShowCSVModal(false);

  const handleModalOpen = (email) => {
    const emp = allEmployees.find(e => e.email === email);

    setModalData({
      id: emp.id,
      first_name: emp.first_name,
      last_name: emp.last_name,
      email: emp.email,
      job_role: emp.job_role,
      active: emp.active,
    });
    handleShow();
  };

  const updateModalData = (key, value) => {
    setNoModalChange(false);
    setModalData({
      ...modalData,
      [key]: value.target.value,
    });
  };

  const updateInviteModalData = (key, value) => {
    if (key === 'email') {
      setInviteModalData({
        ...inviteModalData,
        [key]: value.target.value.toLowerCase(),
      });
    } else {
      setInviteModalData({
        ...inviteModalData,
        [key]: value.target.value,
      });
    }
  };

  const handleModalArchive = async () => {
    setModalLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/employee/${modalData.id}/archive/`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      fetchEmployees();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      setNoModalChange(true);
      handleClose();
    }
  };

  const handleModalActivate = async () => {
    setModalLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/employee/${modalData.id}/activate/`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      fetchEmployees();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      setNoModalChange(true);
      handleClose();
    }
  };

  const handleModalSave = async () => {
    setModalLoading(true);
    try {
      await axios.patch(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/employee/${modalData.id}/`, {
        job_role: modalData.job_role,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      const tempEmployees = [...allEmployees];
      const idx = tempEmployees.findIndex(e => e.id === modalData.id);
      tempEmployees[idx] = modalData;
      setAllEmployees(tempEmployees);
      setShownEmployees(tempEmployees);
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      setNoModalChange(true);
      handleClose();
    }
  };

  const handleInviteModalSave = async () => {
    setValidated(true);
    const form = document.querySelector('#inviteForm');
    if (form.checkValidity() === false) {
      return;
    }
    setInviteModalLoading(true);
    try {
      const {
        data: response,
      } = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/employees/`, [inviteModalData], {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchEmployees();
      if (response.detail === 'Email address is already in use.') {
        setShowEmailTakenToast(true);
      } else {
        setShowGoodToast(true);
      }
    } catch (e) {
      setShowBadToast(true);
    } finally {
      handleInviteModalClose();
      setInviteModalLoading(false);
    }
  };

  const handleFiles = async (files) => {
    try {
      const file = files[0];
      let txt = await file.text();
      txt = txt.split('\n');

      const temp = [];
      for (let i = 1; i < txt.length; i += 1) {
        temp.push(txt[i].split(','));
      }

      const data = [];
      for (let i = 0; i < temp.length; i += 1) {
        if (temp[i][0] && temp[i][1] && temp[i][2]) {
          data.push({
            email: temp[i][0].replace(/(\r\n|\n|\r)/gm, ''),
            first_name: temp[i][1].replace(/(\r\n|\n|\r)/gm, ''),
            last_name: temp[i][2].replace(/(\r\n|\n|\r)/gm, ''),
            job_role: parseInt(temp[i][3], 10) ? parseInt(temp[i][3], 10) : 1,
          });
        }
      }
      const {
        data: response,
      } = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/employees/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      fetchEmployees();
      if (response.detail === 'Email address is already in use.') {
        setShowEmailTakenToast(true);
      } else {
        setShowGoodToast(true);
      }
    } catch (e) {
      setShowBadToast(true);
    } finally {
      handleCSVModalClose();
    }
  };

  function statusFormatter(cell, row, rowIndex, loading) {
    return (
      <span className="iconHolder">
        {row.active && <td><FontAwesomeIcon style={{ color: 'green', marginRight: '5px' }} icon={faCircle} />Active</td>}
        {!row.active && <td><FontAwesomeIcon style={{ color: 'red', marginRight: '5px' }} icon={faCircle} />Archived</td>}
      </span>
    );
  }
  const columns = [{
    dataField: 'first_name',
    text: 'First Name',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'last_name',
    text: 'Last Name',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'job_role',
    text: 'Permission Level',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'active',
    text: 'Status',
    sort: true,
    style: { paddingBottom: '.75em' },
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
    formatter: statusFormatter,
    editCellStyle: { border: 'none' },
  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleModalOpen(row.email);
    },
  };

  return (
    <div style={{ width: '100%', backgroundColor: darkMode && 'rgb(26, 26, 29)' }}>
      <ToastContainer>
        <Toast onClose={() => setShowGoodToast(false)} show={showGoodToast} delay={1500} autohide>
          <ToastBody>
            Success!
          </ToastBody>
        </Toast>
      </ToastContainer>
      <ToastContainer>
        <Toast onClose={() => setShowBadToast(false)} show={showBadToast} delay={3000} autohide>
          <ToastBody>
            An Error Has Occurred.
          </ToastBody>
        </Toast>
      </ToastContainer>
      <ToastContainer>
        <Toast onClose={() => setShowEmailTakenToast(false)} show={showEmailTakenToast} delay={1500} autohide>
          <ToastBody>
            Email/Emails already in use.
          </ToastBody>
        </Toast>
      </ToastContainer>
      <Card style={{ width: '100%' }}>
        <SearchContainer>
          <Form.Control
            type="input"
            placeholder="Search Coaches..."
            onChange={handleInputChange}
            value={inputValue}
            style={{ width: 'auto' }}
            className="darkInput"
          />
          <SearchButtonContainer>
            <Button style={{ marginRight: '10px' }} variant={darkMode ? 'primaryGold' : 'primary'} onClick={handleInviteModalShow}>Invite Coach</Button>
            <Button variant={darkMode ? 'primaryGold' : 'primary'} onClick={handleCSVModalShow}>Import CSV</Button>
          </SearchButtonContainer>
        </SearchContainer>
        <TableContainer>
          { loading
            ? (
              <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                <Spinner animation="border" variant={darkMode ? 'light' : 'dark'} />
              </div>
            )
            : (
              <BootstrapTable bootstrap4 keyField="coachesTable" data={shownEmployees} columns={columns} rowEvents={rowEvents} rowStyle={{ cursor: 'pointer' }} hover />
            )}
        </TableContainer>
      </Card>
      <Modal show={show} onHide={handleClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Update Coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                Email
              </Form.Label>
              <Col sm="7">
                <Form.Control disabled plaintext readOnly defaultValue={modalData.email} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextFirstName">
              <Form.Label column sm="5">
                First Name
              </Form.Label>
              <Col sm="7">
                <Form.Control disabled plaintext readOnly defaultValue={modalData.first_name} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextLastName">
              <Form.Label column sm="5">
                Last Name
              </Form.Label>
              <Col sm="7">
                <Form.Control disabled plaintext readOnly defaultValue={modalData.last_name} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextJobRole">
              <Form.Label column sm="5">
                Permission Level
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  as="select"
                  required
                  defaultValue={modalData.job_role}
                  onChange={(permission) => { updateModalData('job_role', permission); }}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {modalData.active && (
              <Button variant={darkMode ? 'primaryGold' : 'danger'} disabled={modalLoading} onClick={handleModalArchive}>
                Archive
              </Button>
            )}
            {!modalData.active && (
              <Button variant={darkMode ? 'primaryGold' : 'success'} disabled={modalLoading} onClick={handleModalActivate}>
                Activate
              </Button>
            )}
            <div style={{ display: 'flex' }}>
              <Button variant={darkMode ? 'outlineGold' : 'secondary'} disabled={modalLoading} onClick={handleClose} style={{ marginRight: '10px' }}>
                Close
              </Button>
              <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={modalLoading || noModalChange} onClick={handleModalSave}>
                Save Changes
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showInviteModal} onHide={handleInviteModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="inviteForm">
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                Email
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px' }}
                  type="email"
                  onChange={(val) => { updateInviteModalData('email', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextFirstName">
              <Form.Label column sm="5">
                First Name
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode ? '#495057' : 'inherit' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('first_name', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid first name.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextLastName">
              <Form.Label column sm="5">
                Last Name
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode ? '#495057' : 'inherit'  }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('last_name', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid last name.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextJobRole">
              <Form.Label column sm="5">
                Permission Level
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  as="select"
                  required
                  onChange={(val) => { updateInviteModalData('job_role', val); }}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={darkMode ? 'outlineGold' : 'primary'} onClick={handleInviteModalClose}>
            Close
          </Button>
          <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={inviteModalLoading} onClick={handleInviteModalSave}>
            Send Invite
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCSVModal} onHide={handleCSVModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Multiple Coaches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropzone onDrop={acceptedFiles => handleFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ cursor: 'pointer' }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style ={{ backgroundColor: 'lightgray', border: '1px solid gray', height: '150px', textAlign: 'center' }}>
                    <span style={{ color: darkMode && 'black' }}>Drag and drop files here or click to upload</span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ fontSize: '12px', width: '100%' }}>Uploaded CSV's require a specific format. <br /><a href={coachSampleCSV}>Click here</a> to download a sample CSV that contains proper syntax</div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Employees;


Employees.propTypes = {
  darkMode: boolean.isRequired,
};
