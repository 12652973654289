/* eslint-disable no-await-in-loop */

import React, { useEffect, useState } from 'react';
import {
  boolean,
} from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Toast,
} from 'react-bootstrap';
import {
  TableContainer,
  SearchContainer,
  SearchButtonContainer,
  ToastBody,
  ToastContainer,
} from './index.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import deviceAddSample from '../../static/files/device_add_sample.csv';

function DevicesPage({
  darkMode,
}) {
  const authToken = sessionStorage.getItem('authToken');
  const COMPANYID = sessionStorage.getItem('company_id');

  const [loading, setLoading] = useState(true);
  const [gymDevices, setGymDevices] = useState([]);
  const [shownDevices, setShownDevices] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [validated, setValidated] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [reassignModalLoading, setReassignModalLoading] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [reassignModalShow, setReassignModalShow] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [reassignDeviceName, setReassignDeviceName] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showGoodToast, setShowGoodToast] = useState(false);
  const [showBadToast, setShowBadToast] = useState(false);

  const fetchDevices = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/devices/`;
      const devices = [];
      while (url) {
        const {
          data,
        } = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
        devices.push(...data.results);
        url = data.next;
      }
      setGymDevices(devices);
      setShownDevices(devices);
    } catch (e) {
      showBadToast(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleInputChange = (event) => {
    let val = event.target.value;
    setInputValue(val);
    if (val === '') {
      setShownDevices(gymDevices);
    } else {
      val = val.toLowerCase();
      setShownDevices(gymDevices.filter(e => e.device_name.toLowerCase().indexOf(val) !== -1
      || e.email.toLowerCase().indexOf(val) !== -1));
    }
  };

  const handleDeviceNameChange = (event) => {
    const val = event.target.value;
    setDeviceName(val);
  };

  const handleNewUserEmailChange = (event) => {
    const val = event.target.value;
    setNewUserEmail(val);
  };

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleReassignModalClose = () => setReassignModalShow(false);
  const handleReassignModalShow = () => setReassignModalShow(true);

  const handleCSVModalShow = () => setShowCSVModal(true);
  const handleCSVModalClose = () => setShowCSVModal(false);

  const createDevice = async () => {
    try {
      setButtonLoading(true);
      setModalLoading(true);
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/gym_device/`, {
        devices: [{ device_name: deviceName }],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchDevices();
      setDeviceName('');
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      handleModalClose();
      setButtonLoading(false);
    }
  };

  const removeDevice = async (device) => {
    try {
      setButtonLoading(true);
      await axios.delete(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/remove_device/`, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
        data: {
          device_name: device,
        },
      });
      await fetchDevices();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setButtonLoading(false);
    }
  };

  const reassignDevice = async () => {
    setValidated(true);
    const form = document.querySelector('#reassignForm');
    if (form.checkValidity() === false) {
      return;
    }
    try {
      setButtonLoading(true);
      setReassignModalLoading(true);
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/reassign_device/`, {
        device_name: reassignDeviceName,
        email: newUserEmail,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchDevices();
      setShowGoodToast(true);
      setReassignDeviceName('');
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setReassignModalLoading(false);
      setValidated(false);
      handleReassignModalClose();
      setButtonLoading(false);
    }
  };

  const handleFiles = async (files) => {
    try {
      const file = files[0];
      const txt = await file.text();
      const data = txt.split('\n').slice(1);
      for (let i = 0; i < data.length; i += 1) {
        data[i] = data[i].replace(/(\r\n|\n|\r)/gm,"");
      }
      for (let i = 0; i < data.length; i += 1) {
        data[i] = { device_name: data[i] };
      }
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/gym_device/`, {
        devices: data,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchDevices();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      handleCSVModalClose();
    }
  };

  function actionsFormatter(cell, row, rowIndex, extraData) {
    return (
      <div>
        <Button variant={extraData.darkMode ? 'outlineGold' : 'danger'} style={{ marginRight: '10px' }} disabled={extraData.buttonLoading} onClick={() => { removeDevice(row.device_name); }}>
          Remove
        </Button>
        <Button variant={extraData.darkMode ? 'outlineGold' : 'warning'} disabled={extraData.buttonLoading} onClick={() => { setReassignDeviceName(row.device_name); handleReassignModalShow(); }}>
          Reassign
        </Button>
      </div>
    );
  }

  const columns = [{
    dataField: 'device_name',
    text: 'Name',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'email',
    text: 'Assigned To',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    isDummyField: true,
    dataField: 'remove',
    text: 'Actions',
    formatter: actionsFormatter,
    formatExtraData: { buttonLoading, darkMode },
  }];

  return (
    <div style={{ width: '100%', backgroundColor: darkMode && 'rgb(26, 26, 29)' }}>
      <ToastContainer>
        <Toast onClose={() => setShowGoodToast(false)} show={showGoodToast} delay={1500} autohide>
          <ToastBody>
            Success!
          </ToastBody>
        </Toast>
      </ToastContainer>
      <ToastContainer>
        <Toast onClose={() => setShowBadToast(false)} show={showBadToast} delay={3000} autohide>
          <ToastBody>
            An Error Has Occurred.
          </ToastBody>
        </Toast>
      </ToastContainer>
      <Card style={{ width: '100%' }}>
        <SearchContainer>
          <Form.Control
            type="input"
            placeholder="Search Devices..."
            onChange={handleInputChange}
            value={inputValue}
            style={{ width: 'auto' }}
            className={darkMode ? 'darkInput' : ''}
          />
          <SearchButtonContainer>
            <Button
              variant={darkMode ? 'primaryGold' : 'primary'}
              onClick={handleModalShow}
              style={{ marginRight: '10px' }}
            >
              Add Device
            </Button>
            <Button variant={darkMode ? 'primaryGold' : 'primary'} onClick={handleCSVModalShow}>Import CSV</Button>
          </SearchButtonContainer>
        </SearchContainer>
        <TableContainer>
          { loading
            ? (
              <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                <Spinner animation="border" variant={darkMode ? 'light' : 'dark'} />
              </div>
            )
            : (
              <BootstrapTable bootstrap4 keyField="id" data={shownDevices} columns={columns} />
            )}
        </TableContainer>
      </Card>
      <Modal show={modalShow} onHide={handleModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Add Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="userForm">
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                Device Name
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  value={deviceName}
                  onChange={handleDeviceNameChange}
                  required
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={darkMode ? 'outlineGold' : 'secondary'} onClick={handleModalClose}>
            Close
          </Button>
          <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={modalLoading} onClick={createDevice}>
            Add Device
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reassignModalShow} onHide={handleReassignModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Reassign Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="reassignForm">
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                New User Email
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  type="email"
                  value={newUserEmail}
                  onChange={handleNewUserEmailChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={darkMode ? 'outlineGold' : 'secondary'} onClick={handleReassignModalClose}>
            Close
          </Button>
          <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={reassignModalLoading} onClick={reassignDevice}>
            Reassign Device
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCSVModal} onHide={handleCSVModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Add Multiple Devices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropzone onDrop={acceptedFiles => handleFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ cursor: 'pointer' }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style ={{ backgroundColor: 'lightgray', border: '1px solid gray', height: '150px', textAlign: 'center' }}>
                    <span style={{ color: darkMode && 'black' }}>Drag and drop files here or click to upload</span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ fontSize: '12px', width: '100%' }}>Uploaded CSV's require a specific format. <br /><a href={deviceAddSample}>Click here</a> to download a sample CSV that contains proper syntax</div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DevicesPage;

DevicesPage.propTypes = {
  darkMode: boolean.isRequired,
};
