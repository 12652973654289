import React, { useEffect, useState } from 'react';
import {
  boolean,
} from 'prop-types';
import axios from 'axios';
import {
  Card,
  Button,
  Form,
  Col,
} from 'react-bootstrap';
import {
  PageContainer
} from './index.style';
import StateSelect from './Components/stateSelect';

function AccountPage({
  darkMode,
}) {
  const COMPANYID = sessionStorage.getItem('company_id');
  const authToken = sessionStorage.getItem('authToken');

  const [companyData, setCompanyData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [contactUpdated, setContactUpdated] = useState(false);
  const [companyUpdated, setCompanyUpdated] = useState(false);

  const fetchCompany = async () => {
    const {
      data,
    } = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/`, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'portal',
        'x-api-publicid': authToken,
      },
    });
    if (data) {
      setCompanyData(data);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  const updateCompanyData = (key, val) => {
    const companyDataCompany = { ...companyData };
    companyDataCompany[key] = val.target.value;
    setCompanyData(companyDataCompany);
    setCompanyUpdated(true);
  };

  const updateContactData = (key, val) => {
    const companyDataCompany = { ...companyData };
    companyDataCompany[key] = val.target.value;
    setCompanyData(companyDataCompany);
    setContactUpdated(true);
  };

  const updateCompany = async () => {
    setPageLoading(true);
    try {
      await axios.patch(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/`, companyData, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      fetchCompany();
    } catch (e) {
      setPageLoading(false);
    }
  };

  return (
    <div style={{ width: '100%', backgroundColor: darkMode && 'rgb(26, 26, 29)' }}>
      <div style={{ width: '80%', margin: 'auto', marginTop: '1%' }}>
        {!pageLoading && (
          <PageContainer darkMode={darkMode}>
            <Card style={{ padding: '10px' }} id="card1">
              <Card.Title>{companyData.legal_name}</Card.Title>
              <Form>
                <Form.Group controlId="formFacilityName">
                  <Form.Label>Facility</Form.Label>
                  <Form.Control
                    defaultValue={companyData.facility_name}
                    onChange={(val) => { updateCompanyData('facility_name', val); }}
                  />
                </Form.Group>

                <Form.Group controlId="formGridAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    defaultValue={companyData.address}
                    onChange={(val) => { updateCompanyData('address', val); }}
                  />
                </Form.Group>

                <Form.Group controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    defaultValue={companyData.country}
                    onChange={(val) => { updateCompanyData('country', val); }}
                  />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      defaultValue={companyData.city}
                      onChange={(val) => { updateCompanyData('city', val); }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>State</Form.Label>
                    <StateSelect
                      defaultState={companyData.state}
                      onChange={(val) => { updateCompanyData('state', val); }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridZip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      defaultValue={companyData.postal_code}
                      onChange={(val) => { updateCompanyData('postal_code', val); }}
                    />
                  </Form.Group>
                </Form.Row>

                <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={!companyUpdated} onClick={updateCompany}>
                  Update
                </Button>
              </Form>
            </Card>
            <Card style={{ padding: '10px', marginTop: '20px' }} id="card2">
              <Card.Title>Primary Contact</Card.Title>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} controlId="formContactFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      defaultValue={companyData.contact_first_name}
                      onChange={(val) => { updateContactData('contact_first_name', val); }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formContactLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      defaultValue={companyData.contact_last_name}
                      onChange={(val) => { updateContactData('contact_last_name', val); }}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="formContactEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    defaultValue={companyData.email}
                    onChange={(val) => { updateContactData('email', val); }}
                  />
                </Form.Group>

                <Form.Group controlId="formContactPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    defaultValue={companyData.phone}
                    onChange={(val) => { updateContactData('phone', val); }}
                  />
                </Form.Group>

                <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={!contactUpdated} onClick={updateCompany}>
                  Update
                </Button>
              </Form>
            </Card>
          </PageContainer>
        )}
      </div>
    </div>
  );
}

export default AccountPage;

AccountPage.propTypes = {
  darkMode: boolean.isRequired,
};
