
import React, { useEffect, useState } from 'react';
import {
  boolean,
} from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Toast,
  InputGroup,
} from 'react-bootstrap';
import {
  SearchButtonContainer,
  SearchContainer,
  TableContainer,
  ToastBody,
  ToastContainer,
} from './index.style';
import 'bootstrap/dist/css/bootstrap.min.css';

function RoomsPage({
  darkMode,
}) {
  const authToken = sessionStorage.getItem('authToken');
  const COMPANYID = sessionStorage.getItem('company_id');

  const [loading, setLoading] = useState(true);
  const [shownRooms, setShownRooms] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [validated, setValidated] = useState(false);
  const [createRoomModalShow, setCreateRoomModalShow] = useState(false);
  const [editRoomModalShow, setEditRoomModalShow] = useState(false);
  const [editRoomModalContent, setEditRoomModalContent] = useState({
    id: null,
    name: '',
    hub_ids: [],
  });
  const [editRoomLoading, setEditRoomLoading] = useState(false);
  const [createRoomModalContent, setCreateRoomModalContent] = useState({
    name: '',
    hub_ids: [],
  });
  const [createRoomLoading, setCreateRoomLoading] = useState(false);
  const [showGoodToast, setShowGoodToast] = useState(false);
  const [showBadToast, setShowBadToast] = useState(false);

  const fetchRooms = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/rooms/`;
      const tempRooms = [];
      while (url) {
        const {
          data,
        // eslint-disable-next-line no-await-in-loop
        } = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
        tempRooms.push(...data.results);
        url = data.next;
      }
      const rooms = [];
      for (let i = 0; i < tempRooms.length; i += 1) {
        const rm = tempRooms[i];
        rooms.push({
          id: rm.uuid,
          name: rm.name,
          hubs: rm.hub_ids,
          hubCount: rm.hub_ids.length,
        });
      }
      setAllRooms(rooms);
      setShownRooms(rooms);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  const handleInputChange = (blah, el) => {
    const val = blah.target.value.toLowerCase();
    setInputValue(el);
    if (val === '') {
      setShownRooms(allRooms);
    } else {
      setShownRooms(allRooms.filter(e => e.name.toLowerCase().indexOf(val) !== -1));
    }
  };

  const handleCreateRoomModalShow = () => {
    setCreateRoomModalShow(true);
  };

  const handleCreateRoomModalClose = () => {
    setCreateRoomModalShow(false);
  };

  const handleCreateRoomNameChange = (event) => {
    const val = event.target.value;
    setCreateRoomModalContent({
      ...createRoomModalContent,
      name: val,
    });
  };

  const handleCreateRoomModalSave = async () => {
    setValidated(true);
    const form = document.querySelector('#roomCreateForm');
    if (form.checkValidity() === false) {
      return;
    }
    try {
      setCreateRoomLoading(true);
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/rooms/`,
        createRoomModalContent,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
      fetchRooms();
      handleCreateRoomModalClose();
      setShowGoodToast(true);
      setCreateRoomModalContent({
        name: '',
        hub_ids: [],
      });
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setCreateRoomLoading(false);
      setValidated(false);
    }
  };

  const handleEditRoomModalOpen = (row) => {
    const room = allRooms.find(e => e.id === row.id);
    setEditRoomModalContent({
      id: room.id,
      name: room.name,
      hub_ids: room.hubs,
    });
    setEditRoomModalShow(true);
  };

  const handleEditRoomModalClose = () => {
    setEditRoomModalShow(false);
  };

  const handleEditRoomNameChange = (event) => {
    const val = event.target.value;
    setEditRoomModalContent({
      ...editRoomModalContent,
      name: val,
    });
  };

  const handleEditRoomSave = async () => {
    setValidated(true);
    const form = document.querySelector('#roomEditForm');
    if (form.checkValidity() === false) {
      return;
    }
    try {
      setEditRoomLoading(true);
      await axios.patch(`${process.env.REACT_APP_SERVER_BASE}/rooms/${editRoomModalContent.id}/`,
        editRoomModalContent,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
      fetchRooms();
      handleEditRoomModalClose();
      setShowGoodToast(true);
      setEditRoomModalContent({
        id: null,
        name: '',
        hub_ids: [],
      });
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setEditRoomLoading(false);
      setValidated(false);
    }
  };

  const handleEditRoomDestroy = async () => {
    try {
      setEditRoomLoading(true);
      await axios.delete(`${process.env.REACT_APP_SERVER_BASE}/rooms/${editRoomModalContent.id}/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
      fetchRooms();
      handleEditRoomModalClose();
      setShowGoodToast(true);
      setEditRoomModalContent({
        id: null,
        name: '',
        hub_ids: [],
      });
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setEditRoomLoading(false);
      setValidated(false);
    }
  };

  const roomsColumns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        const { order } = sortElement.props;
        return (
          <div style={{ cursor: 'pointer' }}>
            {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
          </div>
        );
      },
    }, {
      dataField: 'hubCount',
      text: 'Hubs',
      sort: true,
      headerFormatter: (column, index, { sortElement, filterElement }) => {
        const { order } = sortElement.props;
        return (
          <div style={{ cursor: 'pointer' }}>
            {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
          </div>
        );
      },
    }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleEditRoomModalOpen(row);
    },
  };

  return (
    <div style={{ width: '100%', backgroundColor: darkMode && 'rgb(26, 26, 29)' }}>
      <ToastContainer>
        <Toast onClose={() => setShowGoodToast(false)} show={showGoodToast} delay={1500} autohide>
          <ToastBody>
            Success!
          </ToastBody>
        </Toast>
      </ToastContainer>
      <ToastContainer>
        <Toast onClose={() => setShowBadToast(false)} show={showBadToast} delay={3000} autohide>
          <ToastBody>
            An Error Has Occurred.
          </ToastBody>
        </Toast>
      </ToastContainer>
      <Card style={{ width: '100%' }}>
        <SearchContainer>
          <div>
            <Form.Control
              type="input"
              placeholder="Search Rooms..."
              onChange={handleInputChange}
              value={inputValue}
              style={{ width: 'auto' }}
              className="darkInput"
            />
          </div>
          <SearchButtonContainer>
            <Button
              onClick={handleCreateRoomModalShow}
              variant={darkMode ? 'primaryGold' : 'primary'}
            >
              Create Room
            </Button>
          </SearchButtonContainer>
        </SearchContainer>
        <TableContainer>
          { loading
            ? (
              <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                <Spinner animation="border" variant={darkMode ? 'light' : 'dark'} />
              </div>
            )
            : (
              <BootstrapTable bootstrap4 keyField="roomsTable" data={shownRooms} columns={roomsColumns} hover rowEvents={rowEvents} rowStyle={{ cursor: 'pointer' }} />
            )}
        </TableContainer>
      </Card>
      <Modal show={createRoomModalShow} onHide={handleCreateRoomModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Create Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="roomCreateForm">
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                Name
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  value={createRoomModalContent.name}
                  onChange={handleCreateRoomNameChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={darkMode ? 'outlineGold' : 'secondary'} onClick={handleCreateRoomModalClose}>
            Cancel
          </Button>
          <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={createRoomLoading} onClick={handleCreateRoomModalSave}>
            Create Room
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={editRoomModalShow} onHide={handleEditRoomModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {editRoomModalContent.name} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="roomEditForm">
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                Name
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  type="text"
                  value={editRoomModalContent.name}
                  onChange={handleEditRoomNameChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Hubs
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Row className="justify-content-md-center">
                <Col sm="7">
                  {editRoomModalContent.hub_ids && editRoomModalContent.hub_ids.map((e, idx) => (
                    <InputGroup id={idx} style={{ marginTop: '5px' }}>
                      <Form.Control
                        value={e}
                        placeholder="Hub ID"
                        disabled
                        plaintext
                        readOnly
                      />
                    </InputGroup>
                  ))}
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button variant={darkMode ? 'primaryGold' : 'danger'} disabled={editRoomLoading} onClick={handleEditRoomDestroy}>
              Destroy
            </Button>
            <div style={{ display: 'flex' }}>
              <Button variant={darkMode ? 'outlineGold' : 'secondary'} onClick={handleEditRoomModalClose}>
                Cancel
              </Button>
              <Button style={{ marginLeft: '10px' }} variant={darkMode ? 'primaryGold' : 'primary'} disabled={editRoomLoading} onClick={handleEditRoomSave}>
                Save Room
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RoomsPage;

RoomsPage.propTypes = {
  darkMode: boolean.isRequired,
};
