/* eslint-disable no-await-in-loop */

import React, { useEffect, useState } from 'react';
import {
  boolean,
} from 'prop-types';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Spinner,
  Toast,
} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import {
  SearchContainer,
  SearchButtonContainer,
  TableContainer,
  ToastBody,
  ToastContainer,
} from './index.style';
import companySampleCSV from '../../static/files/company_sample_csv.csv';

function AdminPage({
  darkMode,
}) {
  const authToken = sessionStorage.getItem('authToken');

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [shownCompanies, setShownCompanies] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [modalData, setModalData] = useState({
    facility_name: null,
    address: null,
  });
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [showGoodToast, setShowGoodToast] = useState(false);
  const [showBadToast, setShowBadToast] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [createModalData, setCreateModalData] = useState({
    address: null,
    city: null,
    contact_first_name: null,
    contact_last_name: null,
    country: null,
    email: null,
    facility_name: null,
    group: 'fitness',
    legal_name: null,
    phone: null,
    state: null,
    timezone: 'NA',
    postal_code: null,
  });

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_SERVER_BASE}/company/`;
      const getCompanies = [];
      while (url) {
        const {
          data,
        } = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
        for (let i = 0; i < data.results.length; i += 1) {
          if (!data.results[i].deleted) {
            getCompanies.push(data.results[i]);
          }
        }
        url = data.next;
      }
      setCompanies(getCompanies);
      setShownCompanies(getCompanies);
    } catch (e) {
      showBadToast(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleInputChange = (blah, el) => {
    const val = blah.target.value.toLowerCase();
    setInputValue(el);
    if (val === '') {
      setShownCompanies(companies);
    } else {
      setShownCompanies(companies.filter(e => (e.legal_name && e.legal_name.toLowerCase().indexOf(val) !== -1)
      || (e.facility_name && e.facility_name.toLowerCase().indexOf(val) !== -1)
      || (e.contact && e.contact.toLowerCase().indexOf(val) !== -1)
      || (e.email && e.email.toLowerCase().indexOf(val) !== -1)
      || (e.address && e.address.toLowerCase().indexOf(val) !== -1)
      || (e.city && e.city.toLowerCase().indexOf(val) !== -1)
      || (e.country && e.country.toLowerCase().indexOf(val) !== -1)));
    }
  };

  function handleClose() {
    setShow(false);
  }

  function handleOpen() {
    setShow(true);
  }

  const handleCSVModalShow = () => setShowCSVModal(true);
  const handleCSVModalClose = () => setShowCSVModal(false);

  const handleModalOpen = (facName, address) => {
    const comp = companies.find(e => e.facility_name === facName && e.address === address);

    setModalData({
      facility_name: comp.facility_name,
      address: comp.address,
    });
    handleOpen();
  };

  function handleModalConfirm() {
    const comp = companies.find(e => e.facility_name === modalData.facility_name && e.address === modalData.address);
    sessionStorage.setItem('company_id', comp.uuid);
    sessionStorage.setItem('company_name', comp.legal_name);
    setModalData({
      facility_name: null,
      address: null,
    });
    handleClose();
    setShowGoodToast(true);
    window.location.reload(false);
  }

  function handleCreateModalShow() {
    setCreateModalShow(true);
  }

  function handleCreateModalClose() {
    setCreateModalShow(false);
  }

  async function handleCreateModalSave() {
    setValidated(true);
    const form = document.querySelector('#createForm');
    if (form.checkValidity() === false) {
      return;
    }
    setModalLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/`,
        [createModalData], {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
      await fetchCompanies();
      setShowGoodToast(true);
      setValidated(false);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      handleCreateModalClose();
    }
  }

  function updateInviteModalData(key, value) {
    setCreateModalData({
      ...createModalData,
      [key]: value.target.value,
    });
  }

  const handleFiles = async (files) => {
    try {
      const file = files[0];
      let txt = await file.text();
      txt = txt.split('\n');

      const temp = [];
      for (let i = 1; i < txt.length; i += 1) {
        temp.push(txt[i].split(','));
      }

      const data = [];

      for (let i = 0; i < temp.length; i += 1) {
        if (temp[i].length === 13) {
          data.push({
            legal_name: temp[i][0].replace(/(\r\n|\n|\r)/gm, ''),
            facility_name: temp[i][1].replace(/(\r\n|\n|\r)/gm, ''),
            contact_first_name: temp[i][2].replace(/(\r\n|\n|\r)/gm, ''),
            contact_last_name: temp[i][3].replace(/(\r\n|\n|\r)/gm, ''),
            email: temp[i][4].replace(/(\r\n|\n|\r)/gm, ''),
            phone: temp[i][5].replace(/(\r\n|\n|\r)/gm, ''),
            group: temp[i][6].replace(/(\r\n|\n|\r)/gm, ''),
            address: temp[i][7].replace(/(\r\n|\n|\r)/gm, ''),
            city: temp[i][8].replace(/(\r\n|\n|\r)/gm, ''),
            country: temp[i][9].replace(/(\r\n|\n|\r)/gm, ''),
            state: temp[i][10].replace(/(\r\n|\n|\r)/gm, ''),
            postal_code: temp[i][11].replace(/(\r\n|\n|\r)/gm, ''),
            timezone: temp[i][12].replace(/(\r\n|\n|\r)/gm, ''),
          });
        }
      }
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      fetchCompanies();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      handleCSVModalClose();
    }
  };

  const columns = [{
    dataField: 'legal_name',
    text: 'Corporate Name',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'facility_name',
    text: 'Facility Name',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'contact',
    text: 'Contact',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'address',
    text: 'Address',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'city',
    text: 'City',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'country',
    text: 'Country',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'active_employee_count',
    text: 'Active Employees',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'total_employee_count',
    text: 'Total Employees',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'total_user_count',
    text: 'Total Members',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleModalOpen(row.facility_name, row.address);
    },
  };

  return (
    <div style={{ width: '100%', backgroundColor: darkMode && 'rgb(26, 26, 29)' }}>
      <ToastContainer>
        <Toast onClose={() => setShowGoodToast(false)} show={showGoodToast} delay={1500} autohide>
          <ToastBody>
            Success!
          </ToastBody>
        </Toast>
      </ToastContainer>
      <ToastContainer>
        <Toast onClose={() => setShowBadToast(false)} show={showBadToast} delay={3000} autohide>
          <ToastBody>
            An Error Has Occurred.
          </ToastBody>
        </Toast>
      </ToastContainer>
      <Card style={{ width: '100%' }}>
        <SearchContainer>
          <div>
            <Form.Control
              type="input"
              placeholder="Search Companies..."
              onChange={handleInputChange}
              value={inputValue}
              style={{ width: 'auto' }}
              className="darkInput"
            />
          </div>
          <SearchButtonContainer>
            <Button
              variant={darkMode ? 'primaryGold' : 'primary'}
              onClick={handleCreateModalShow}
              style={{ marginRight: '10px' }}
            >
              Create Company
            </Button>
            <Button variant={darkMode ? 'primaryGold' : 'primary'} onClick={handleCSVModalShow}>Import CSV</Button>
          </SearchButtonContainer>
        </SearchContainer>
        <TableContainer>
          { loading
            ? (
              <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                <Spinner animation="border" variant={darkMode ? 'light' : 'dark'} />
              </div>
            )
            : (
              <BootstrapTable bootstrap4 keyField="id" data={shownCompanies} columns={columns} rowEvents={rowEvents} />
            )}
        </TableContainer>
      </Card>
      <Modal show={show} onHide={handleClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Body>
          <span>Use {modalData.facility_name}?</span>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button variant={darkMode ? 'outlineGold' : 'secondary'} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant={darkMode ? 'primaryGold' : 'primary'} onClick={handleModalConfirm}>
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={createModalShow} onHide={handleCreateModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Create Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="createForm">
            <Form.Row>
              <Form.Group as={Col} controlId="formPlaintextLegalName">
                <Form.Label>
                  Legal Name
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('legal_name', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid legal name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formPlaintextFacilityName">
                <Form.Label>
                  Facility Name
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('facility_name', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid facility name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPlaintextFirstName">
                <Form.Label>
                  Contact First Name
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('contact_first_name', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid first name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formPlaintextLastName">
                <Form.Label>
                  Contact Last Name
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('contact_last_name', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid last name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPlaintextEmail">
                <Form.Label>
                  Email
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  type="email"
                  onChange={(val) => { updateInviteModalData('email', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formPlaintextPhone">
                <Form.Label>
                  Phone Number
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('phone', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid phone number.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPlaintextGroup">
                <Form.Label>
                  Group
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  defaultValue="fitness"
                  onChange={(val) => { updateInviteModalData('group', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid group.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formPlaintextAddress">
                <Form.Label>
                  Address
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('address', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid address.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPlaintextCity">
                <Form.Label>
                  City
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('city', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid city.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formPlaintextCountry">
                <Form.Label>
                  Country
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('country', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid country.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPlaintextState">
                <Form.Label>
                  State
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('state', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid state.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formPlaintextPostalCode">
                <Form.Label>
                  Postal Code
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: '#f9fad9', width: 'auto', paddingLeft: '10px', color: darkMode && 'black' }}
                  plaintext
                  onChange={(val) => { updateInviteModalData('postal_code', val); }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid postal code.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={darkMode ? 'outlineGold' : 'secondary'} onClick={handleCreateModalClose}>
            Close
          </Button>
          <Button variant={darkMode ? 'primaryGold' : 'primary'} onClick={handleCreateModalSave} disabled={modalLoading}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCSVModal} onHide={handleCSVModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Create Multiple Companies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropzone onDrop={acceptedFiles => handleFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ cursor: 'pointer' }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style ={{ backgroundColor: 'lightgray', border: '1px solid gray', height: '150px', textAlign: 'center' }}>
                    <span style={{ color: darkMode && 'black' }}>Drag and drop files here or click to upload</span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ fontSize: '12px', width: '100%' }}>Uploaded CSV's require a specific format. <br /><a href={companySampleCSV}>Click here</a> to download a sample CSV that contains proper syntax</div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminPage;

AdminPage.propTypes = {
  darkMode: boolean.isRequired,
};
