import styled from 'styled-components';

const TableContainer = styled.div`
  margin: 15px 15px;
  width: 90%;
  height: 700px;
  overflow-y: scroll;
  font-size: inherit;

  @media (max-width: 1064px) {
    font-size: 14px;
  }

  @media (max-width: 861px) {
    font-size: 12px;
  }

  @media (max-width: 769px) {
    font-size: 11px;
  }

  #inviteTable {
    tr:hover {
      background-color: unset !important;
      color: unset !important;
    } 
  
    td {
      cursor: auto;
    }
  }
`;

const SearchButtonContainer = styled.div`
    display: flex;

    @media (max-width: 505px) {
      margin-top: 10px;
    }
`;

const SearchContainer = styled.div`
    width: 90%;
    margin: 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    
    @media (max-width: 505px) {
      display: block;
      margin-top: 10px;
    } 
`;

const ToastContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`;

const ToastBody = styled.div`
  background-color: slategray;
  color: white;
  font-size: 20px;
  padding: 10px;
`;

export {
  SearchButtonContainer,
  SearchContainer,
  TableContainer,
  ToastContainer,
  ToastBody,
};
