import styled from 'styled-components';

const PageContainer = styled.div`
${props => props.darkMode && `
#card1, #card2 {
  background-color: rgb(35, 35, 39);
  color: white;
}`}
`;

export {
  PageContainer,
};
