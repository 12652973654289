import styled from 'styled-components';
import backgroundImage from '../../static/images/morph_run.jpg';

const BackgroundContainer = styled.div`
  background-image: url(${backgroundImage});
  height: 100%;

  @media only screen and (max-width: 1024px) {
    background-position: center;
  }
`;

const FormContainer = styled.form`
  width: 30%;
  height: auto;
  margin: auto;
  padding-bottom: 100px;
  background: #0E0E0F;
  opacity: 0.9;
  border-radius: 4px;
  text-align: center;

  @media only screen and (max-width: 762px) {
    width: 80%;
  }

  .form-control {
    margin: auto;
    margin-bottom: 10px;
    color: black;
  }
`;

const LogoContainer = styled.img`
  width: 40%;
  margin: auto;
  display: block;
  margin-bottom: 40px;
  padding-top: 30px;

  @media only screen and (max-width: 762px) {
    margin-bottom: 50px;
  }
`;

const ButtonContainer = styled.button`
  margin: auto;
  background: #F0C556;
  border-radius: 5px;
  width: 30%;
`;

export {
  BackgroundContainer,
  ButtonContainer,
  FormContainer,
  LogoContainer,
};
