
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faAngleDown, faMoon, faSun} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import {
  Dropdown,
  Nav,
  Navbar,
  Image,
} from 'react-bootstrap';
import {
  Helmet,
} from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  AdminPage,
  Employees,
  UsersPage,
  AccountPage,
  DevicesPage,
  RoomsPage,
} from '../_export';
import {
  PageWrapper,
  ContentWrapper,
  NavBarContainer,
  ShowOnMobile,
  HideOnMobile,
} from './index.style';
import coachLogo from '../../static/images/MorpheusCoachWhite.svg';
import coachIcon from '../../static/images/CoachAppIcon.png';

import '../../static/style/darkMode.css';
import '../../static/style/general.css';


function TabPage() {
  const history = useHistory();
  const isAdmin = sessionStorage.getItem('IS_ADMIN') === 'true';

  const [activeTable, setActiveTable] = useState(sessionStorage.getItem('active_page') || 'coaches');
  const [darkMode, setDarkMode] = useState(sessionStorage.getItem('darkMode') === 'false' ? false : true);

  useEffect(() => {
    if (!sessionStorage.getItem('authToken')) {
      history.push('/');
    }
  });

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <FontAwesomeIcon style={{ color: '#fff', marginLeft: '5px' }} icon={faAngleDown} />
    </div>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled" style={{ marginBottom: '0' }}>
            {React.Children.toArray(children)}
          </ul>
        </div>
      );
    },
  );

  const logout = () => {
    sessionStorage.clear();
    history.push('/');
  };

  const toggleDarkMode = (e) => {
    const sun = e.currentTarget.id === 'sun';
    if (sun) {
      setDarkMode(false);
      sessionStorage.setItem('darkMode', false);
    } else {
      setDarkMode(true);
      sessionStorage.setItem('darkMode', true);
    }
  };

  return (
    <PageWrapper darkMode={darkMode}>
      <Helmet>
        <title>Morpheus Coach</title>
        <link rel="icon" type="image/png" href={coachIcon} sizes="32x32" />
      </Helmet>
      <Navbar expand="xl">
        <Navbar.Brand style={{ margin: 0 }} href="#home"><Image style={{ width: '90%' }} src={coachLogo} /></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse style={{ justifyContent: 'space-between', marginLeft: '1%' }}>
          <Navbar.Text>
            <span style={{ color: '#fff' }}>{sessionStorage.getItem('company_name')}</span>
            <div style={{ display: 'inline-block', cursor: 'pointer', width: '50%', minWidth: '169px' }}>
              <div className="mobileNoModal" id="sun" onClick={toggleDarkMode} style={{backgroundColor: darkMode ? '#232327' : 'dimgray', display: 'inline-block', width: '22%', padding: '5px 0px', textAlign: 'center' }}>
                <FontAwesomeIcon style={{ height: '15px', width: '15px', color: 'white' }} icon={faSun} />
              </div>
              <div id="moon" onClick={toggleDarkMode} style={{backgroundColor: darkMode ? '#3B3B42' : 'rgb(52, 58, 64)', display: 'inline-block', width: '22%', padding: '5px 0px', textAlign: 'center' }}>
                <FontAwesomeIcon icon={faMoon} style={{ height: '15px', width: '15px', color: 'white' }} />
              </div>
            </div>
          </Navbar.Text>
          <Dropdown style={{ cursor: 'pointer' }}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <Navbar.Text style={{ borderBottom: '1px solid gold', color: 'gray' }}>
                Signed in as: <span style={{ color: '#fff' }}>{`${sessionStorage.getItem('first_name')} ${sessionStorage.getItem('last_name')}`}</span>
              </Navbar.Text>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} style={{ padding: 'inherit' }}>
              <Dropdown.Item eventKey="1" onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ShowOnMobile>
            <Nav activeKey={activeTable} active className="flex-column" onSelect={(e) => { setActiveTable(e); sessionStorage.setItem('active_page', e); }}>
              {isAdmin && (
                <Nav.Link eventKey="admin" style={{ fontSize: '1em', color: 'rgb(255, 255, 255)' }}>
                  {activeTable === 'admin' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                  Admin
                </Nav.Link>
              )}
              <Nav.Link eventKey="coaches" style={{ fontSize: '1em', color: 'rgb(255, 255, 255)' }}>
                {activeTable === 'coaches' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Coaches
              </Nav.Link>
              <Nav.Link eventKey="devices" style={{ fontSize: '1em', color: 'rgb(255, 255, 255)' }}>
                {activeTable === 'devices' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Devices
              </Nav.Link>
              <Nav.Link eventKey="members" style={{ fontSize: '1em', color: 'rgb(255, 255, 255)' }}>
                {activeTable === 'members' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Members
              </Nav.Link>
              <Nav.Link eventKey="rooms" style={{ fontSize: '1em', color: 'rgb(255, 255, 255)' }}>
                {activeTable === 'rooms' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Rooms
              </Nav.Link>
              <Nav.Link eventKey="account" style={{ fontSize: '1em', color: 'rgb(255, 255, 255)' }}>
                {activeTable === 'account' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Account
              </Nav.Link>
            </Nav>
          </ShowOnMobile>
        </Navbar.Collapse>
      </Navbar>
      <ContentWrapper>
        <HideOnMobile>
          <NavBarContainer darkMode={darkMode}>
            <Nav activeKey={activeTable} className="flex-column" onSelect={(e) => { setActiveTable(e); sessionStorage.setItem('active_page', e); }}>
              {isAdmin && (
                <Nav.Link eventKey="admin">
                  {activeTable === 'admin' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                  Admin
                </Nav.Link>
              )}
              <Nav.Link eventKey="coaches">
                {activeTable === 'coaches' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Coaches
              </Nav.Link>
              <Nav.Link eventKey="devices">
                {activeTable === 'devices' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Devices
              </Nav.Link>
              <Nav.Link eventKey="members">
                {activeTable === 'members' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Members
              </Nav.Link>
              <Nav.Link eventKey="rooms">
                {activeTable === 'rooms' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Rooms
              </Nav.Link>
              <Nav.Link eventKey="account">
                {activeTable === 'account' && <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px', width: '.7em' }} />}
                Account
              </Nav.Link>
            </Nav>
          </NavBarContainer>
        </HideOnMobile>
        {activeTable === 'admin' && <AdminPage darkMode={darkMode} />}
        {activeTable === 'coaches' && <Employees darkMode={darkMode} />}
        {activeTable === 'devices' && <DevicesPage darkMode={darkMode} />}
        {activeTable === 'members' && <UsersPage darkMode={darkMode} />}
        {activeTable === 'rooms' && <RoomsPage darkMode={darkMode} />}
        {activeTable === 'account' && <AccountPage darkMode={darkMode} />}
      </ContentWrapper>
    </PageWrapper>
  );
}

export default TabPage;
