import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  Form,
} from 'react-bootstrap';
import {
  Helmet,
} from 'react-helmet';

import {
  BackgroundContainer,
  ButtonContainer,
  FormContainer,
  LogoContainer,
} from './index.style';
import coachIcon from '../../static/images/CoachAppIcon.png';
import CoachImage from '../../static/images/MorpheusCoachWhite.svg';

function HomePage() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    if (sessionStorage.authToken) {
      history.push('/home');
    }
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');
    try {
      const {
        data,
      } = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/auth/employee/login/`,
        {
          email,
          password,
        });

      console.log(data);
      if (data.token) {
        if (data.employee.job_role < 4) {
          setLoginError('Permission Denied');
          return;
        }
        window.sessionStorage.setItem('company_id', data.employee.company);
        window.sessionStorage.setItem('first_name', data.employee.first_name);
        window.sessionStorage.setItem('last_name', data.employee.last_name);
        window.sessionStorage.setItem('company_name', data.company.legal_name);
        window.sessionStorage.setItem('IS_ADMIN', data.employee.account.toString() === process.env.REACT_APP_GRID_ADMIN_ID);
        const auth = btoa(`${email}:${password}`);
        window.sessionStorage.setItem('authToken', data.public_id);
        history.push('/home');
      }
    } catch (err) {
      setLoginError('Incorrect Credentials');
    }
  };

  const handleChange = (e) => {
    if (e.target.id === 'email') {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  return (
    <BackgroundContainer>
      <Helmet>
        <title>Morpheus Coach</title>
        <link rel="icon" type="image/png" href={coachIcon} sizes="32x32" />
      </Helmet>
      <div style={{ paddingTop: '50px' }}>
        <FormContainer>
          <LogoContainer src={CoachImage} />
          <Form.Control
            type="input"
            placeholder="Email"
            id="email"
            onChange={handleChange}
            value={email}
            style={{ width: 'auto' }}
          />
          <Form.Control
            type="password"
            placeholder="Password"
            id="password"
            onChange={handleChange}
            value={password}
            style={{ width: 'auto' }}
          />
          <ButtonContainer variant="warning" onClick={handleLogin}>Login</ButtonContainer>
          <p id="error" style={{ color: loginError ? 'palevioletred' : 'rgb(14, 14, 15)', paddingTop: '5px', height: '24px' }}>{loginError}</p>
        </FormContainer>
      </div>
    </BackgroundContainer>
  );
}

export default HomePage;
