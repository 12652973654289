
import React, { useEffect, useState } from 'react';
import {
  boolean,
} from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Toast,
} from 'react-bootstrap';
import {
  TableContainer,
  SearchButtonContainer,
  SearchContainer,
  ToastBody,
  ToastContainer,
} from './index.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import memberInviteSample from '../../static/files/member_invite_sample.csv';

function UsersPage({
  darkMode,
}) {
  const authToken = sessionStorage.getItem('authToken');
  const COMPANYID = sessionStorage.getItem('company_id');

  const [membersLoading, setMemberLoading] = useState(true);
  const [invitesLoading, setInvitesLoading] = useState(true);
  const [shownUsers, setShownUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [shownInvitations, setShownInvitations] = useState([]);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [inviteInputVal, setInviteInputVal] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(window.sessionStorage.getItem('checked') === null || window.sessionStorage.getItem('checked') === 'true');
  const [modalData, setModalData] = useState({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    status: '',
  });
  const [validated, setValidated] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [inviteModalLoading, setInviteModalLoading] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showGoodToast, setShowGoodToast] = useState(false);
  const [showBadToast, setShowBadToast] = useState(false);

  const fetchUsers = async () => {
    setMemberLoading(true);
    try {
      let url = `${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/users/active/`;
      const users = [];
      while (url) {
        const {
          data,
        // eslint-disable-next-line no-await-in-loop
        } = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'portal',
            'x-api-publicid': authToken,
          },
        });
        users.push(...data.results);
        url = data.next;
      }

      const newU = [];
      for (let i = 0; i < users.length; i += 1) {
        const {
          pk,
          first_name,
          last_name,
          email: {
            email,
          },
          username,
          joined,
        } = users[i];
        newU.push({
          id: pk,
          first_name,
          last_name,
          email,
          username,
          joined,
        });
      }
      setAllUsers(newU);
      setShownUsers(newU);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setMemberLoading(false);
    }
  };

  const fetchInvitations = async () => {
    setInvitesLoading(true);
    try {
      const {
        data: invites,
      } = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/invitations/pending/`, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      setPendingInvitations(invites);
      setShownInvitations(invites);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setInvitesLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchInvitations();
  }, []);

  const handleInviteEmailChange = (event) => {
    const val = event.target.value;
    setInviteEmail(val);
  };

  const handleInputChange = (blah, el) => {
    const val = blah.target.value.toLowerCase();
    setInputValue(el);
    if (val === '') {
      setShownUsers(allUsers);
    } else {
      setShownUsers(allUsers.filter(e => e.email.toLowerCase().indexOf(val) !== -1
      || e.first_name.toLowerCase().indexOf(val) !== -1 || e.last_name.toLowerCase().indexOf(val) !== -1
      || e.username.toLowerCase().indexOf(val) !== -1));
    }
  };

  const handleInviteInputChange = (blah, el) => {
    const val = blah.target.value.toLowerCase();
    setInviteInputVal(el);
    if (val === '') {
      setShownInvitations(pendingInvitations);
    } else {
      setShownInvitations(pendingInvitations.filter(e => e.user_email.toLowerCase().indexOf(val) !== -1));
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleModalInviteClose = () => setInviteModalShow(false);
  const handleModalInviteShow = () => setInviteModalShow(true);

  const handleCSVModalShow = () => setShowCSVModal(true);
  const handleCSVModalClose = () => setShowCSVModal(false);

  const handleModalOpen = (email) => {
    const user = allUsers.find(e => e.email === email);

    setModalData({
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      username: user.username,
      status: user.status,
    });
    handleShow();
  };

  const handleModalArchive = async () => {
    setModalLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/users/archive/`, {
        user_id: [modalData.id],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchUsers();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      handleClose();
    }
  };

  const handleModalActivate = async () => {
    setModalLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/users/activate/`, {
        user_id: [modalData.id],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchUsers();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      handleClose();
    }
  };

  const handleModalRemove = async () => {
    setModalLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/company/${COMPANYID}/users/remove/`, {
        user_id: [modalData.id],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchUsers();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setModalLoading(false);
      handleClose();
    }
  };

  const handleInviteModalSave = async () => {
    setValidated(true);
    const form = document.querySelector('#userForm');
    if (form.checkValidity() === false) {
      return;
    }
    try {
      setInviteModalLoading(true);
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/invitations/send/`, {
        user_emails: [inviteEmail.toLowerCase()],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchInvitations();
      setInviteEmail('');
      setShowGoodToast();
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setInviteModalLoading(false);
      setValidated(false);
      handleModalInviteClose();
    }
  };

  const sendInvite = async (userEmail) => {
    try {
      setButtonLoading(true);
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/invitations/send/`, {
        user_emails: [userEmail],
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchInvitations();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setButtonLoading(false);
    }
  };

  const revokeInvite = async (userEmail) => {
    try {
      setButtonLoading(true);
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/invitations/revoke/`, {
        user_email: userEmail,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchInvitations();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      setButtonLoading(false);
    }
  };

  const changeUsers = async (event) => {
    const {
      currentTarget: {
        id,
      },
    } = event;
    if (id === 'active') {
      if (!checked) {
        setChecked(true);
        window.sessionStorage.setItem('checked', true);
      }
    } else if (id === 'pending') {
      if (checked) {
        setChecked(false);
        window.sessionStorage.setItem('checked', false);
      }
    }
  };

  const handleFiles = async (files) => {
    try {
      const file = files[0];
      const txt = await file.text();
      const data = txt.split('\n').slice(1);
      for (let i = 0; i < data.length; i += 1) {
        data[i] = data[i].replace(/(\r\n|\n|\r)/gm,"");
      }
      await axios.post(`${process.env.REACT_APP_SERVER_BASE}/invitations/send/`, {
        user_emails: data,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'portal',
          'x-api-publicid': authToken,
        },
      });
      await fetchInvitations();
      setShowGoodToast(true);
    } catch (e) {
      setShowBadToast(true);
    } finally {
      handleCSVModalClose();
    }
  };

  const activeUsersColumns = [{
    dataField: 'first_name',
    text: 'First Name',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'last_name',
    text: 'Last Name',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'username',
    text: 'Username',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'joined',
    text: 'Joined',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleModalOpen(row.email);
    },
  };

  function actionsFormatter(cell, row, rowIndex, extraData) {
    return (
      <div>
        <Button variant={extraData.darkMode ? 'outlineGold' : 'primary'} className="outlineButton" style={{ marginRight: '10px' }} disabled={extraData.buttonLoading} onClick={() => { sendInvite(row.user_email); }}>
          Resend
        </Button>
        <Button variant={extraData.darkMode ? 'outlineGold' : 'warning'} className="outlineButton" disabled={extraData.buttonLoading} onClick={() => { revokeInvite(row.user_email); }}>
          Revoke
        </Button>
      </div>
    );
  }


  const pendingInvitesColumns = [{
    dataField: 'user_email',
    text: 'Email',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    dataField: 'created',
    text: 'Sent',
    sort: true,
    headerFormatter: (column, index, { sortElement, filterElement }) => {
      const { order } = sortElement.props;
      return (
        <div style={{ cursor: 'pointer' }}>
          {column.text} {order === 'desc' && <FontAwesomeIcon icon={faAngleUp} style={{ marginRight: '5px', width: '.7em' }} />} {order === 'asc' && <FontAwesomeIcon icon={faAngleDown} style={{ marginRight: '5px', width: '.7em' }} />}
        </div>
      );
    },
  }, {
    isDummyField: true,
    dataField: 'remove',
    text: 'Actions',
    formatter: actionsFormatter,
    formatExtraData: { buttonLoading, darkMode },
  }];
  return (
    <div style={{ width: '100%', backgroundColor: darkMode && 'rgb(26, 26, 29)' }}>
      <ToastContainer>
        <Toast onClose={() => setShowGoodToast(false)} show={showGoodToast} delay={1500} autohide>
          <ToastBody>
            Success!
          </ToastBody>
        </Toast>
      </ToastContainer>
      <ToastContainer>
        <Toast onClose={() => setShowBadToast(false)} show={showBadToast} delay={3000} autohide>
          <ToastBody>
            An Error Has Occurred.
          </ToastBody>
        </Toast>
      </ToastContainer>
      <Card style={{ width: '100%' }}>
        <SearchContainer>
          <div>
            {checked && (
              <Form.Control
                type="input"
                placeholder="Search Members..."
                onChange={handleInputChange}
                value={inputValue}
                style={{ width: 'auto' }}
                className={darkMode && 'darkInput'}
              />
            )}
            {!checked && (
              <Form.Control
                type="input"
                placeholder="Search Invites..."
                onChange={handleInviteInputChange}
                value={inviteInputVal}
                style={{ width: 'auto' }}
                className={darkMode && 'darkInput'}
              />
            )}
            <div style={{ marginTop: '10px' }} className="darkText">
              <Form.Check
                id="active"
                onChange={changeUsers}
                checked={checked}
                type="checkbox"
                label="All Members"
              />
              <Form.Check
                id="pending"
                onChange={changeUsers}
                checked={!checked}
                type="checkbox"
                label="Pending Invitations"
              />
            </div>
          </div>
          <SearchButtonContainer>
            <Button
              onClick={handleModalInviteShow}
              style={{ height: '40%', marginRight: '10px' }}
              variant={darkMode ? 'primaryGold' : 'primary'}
            >
              Invite Member
            </Button>
            <Button
              style={{ height: '40%' }}
              onClick={handleCSVModalShow}
              variant={darkMode ? 'primaryGold' : 'primary'}
            >
              Import CSV
            </Button>
          </SearchButtonContainer>
        </SearchContainer>
        {checked && (
          <TableContainer>
            { membersLoading
              ? (
                <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                  <Spinner animation="border" variant={darkMode ? 'light' : 'dark'} />
                </div>
              )
              : (
                <BootstrapTable bootstrap4 keyField="membersTable" data={shownUsers} columns={activeUsersColumns} hover rowEvents={rowEvents} rowStyle={{ cursor: 'pointer' }} />
              )}
          </TableContainer>
        )}
        {!checked && (
          <TableContainer>
            { invitesLoading
              ? (
                <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                  <Spinner animation="border" variant={darkMode ? 'light' : 'dark'} />
                </div>
              )
              : (
                <BootstrapTable bootstrap4 id="inviteTable" keyField="invitesTable" data={shownInvitations} columns={pendingInvitesColumns} />
              )}
          </TableContainer>
        )}
      </Card>
      <Modal show={show} onHide={handleClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>{modalData.first_name} {modalData.last_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                First Name
              </Form.Label>
              <Col sm="7">
                <Form.Control disabled plaintext readOnly defaultValue={modalData.first_name} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextFirstName">
              <Form.Label column sm="5">
                Last Name
              </Form.Label>
              <Col sm="7">
                <Form.Control disabled plaintext readOnly defaultValue={modalData.last_name} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextLastName">
              <Form.Label column sm="5">
                Email
              </Form.Label>
              <Col sm="7">
                <Form.Control disabled plaintext readOnly defaultValue={modalData.email} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextLastName">
              <Form.Label column sm="5">
                Username
              </Form.Label>
              <Col sm="7">
                <Form.Control disabled plaintext readOnly defaultValue={modalData.username} />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button className="outlineButton" onClick={handleClose} variant={darkMode ? 'outlineGold' : 'secondary'}>
              Close
            </Button>
            <div style={{ display: 'flex' }}>
              {modalData.status === 'Active' && (
                <Button variant={darkMode ? 'primaryGold' : 'warning'} disabled={modalLoading} onClick={handleModalArchive} style={{ marginRight: '10px' }}>
                  Archive
                </Button>
              )}
              {modalData.status === 'Archived' && (
                <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={modalLoading} onClick={handleModalActivate} style={{ marginRight: '10px' }}>
                  Activate
                </Button>
              )}
              <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={modalLoading} onClick={handleModalRemove}>
                Remove
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={inviteModalShow} onHide={handleModalInviteClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} id="userForm">
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="5">
                Email
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  type="email"
                  value={inviteEmail}
                  onChange={handleInviteEmailChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={darkMode ? 'outlineGold' : 'secondary'} onClick={handleModalInviteClose}>
            Close
          </Button>
          <Button variant={darkMode ? 'primaryGold' : 'primary'} disabled={inviteModalLoading} onClick={handleInviteModalSave}>
            Send Invite
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCSVModal} onHide={handleCSVModalClose} dialogClassName={darkMode && 'modalDark'}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Multiple Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropzone onDrop={acceptedFiles => handleFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ cursor: 'pointer' }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div style ={{ backgroundColor: 'lightgray', border: '1px solid gray', height: '150px', textAlign: 'center' }}>
                    <span style={{ color: darkMode && 'black' }}>Drag and drop files here or click to upload</span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ fontSize: '12px', width: '100%' }}>Uploaded CSV's require a specific format. <br /><a href={memberInviteSample}>Click here</a> to download a sample CSV that contains proper syntax</div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UsersPage;

UsersPage.propTypes = {
  darkMode: boolean.isRequired,
};
