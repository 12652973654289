import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import {
  HomePage,
} from '../pages/_export';
import TabPage from '../pages/Tabs';
import 'bootstrap/dist/css/bootstrap-grid.min.css';

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/home" component={TabPage} exact />
        <Route path="/" component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
