import styled from 'styled-components';

const BACKGROUND_COLOR = '#fafafa';

const PageWrapper = styled.div`
  background-color: ${BACKGROUND_COLOR};
  height: 100%;

  .nav-link {
    margin-top: 10px;
    margin-bottom: 10px;
    color: yellow;
    font-size: 12.5px;
  }

  @media (max-width: 1064px) {
    .nav-link {
      font-size: 10.9px;
    }
  }

  .nav-link:hover {
    background-color: grey;
  }

  .navbar {
    background-color: #343a40;
  }

  .card {
    border-radius: 0;
  }
  
  tbody > tr:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  ${props => props.darkMode && `
  .card {
    background-color: rgb(26, 26, 29);
  }

  thead {
    color: gray;
  }

  tbody {
    color: white
  }

  table {
    background-color: #232327;
    border: none
  }

  td {
    border: none
  }
  
  th {
    border: none
  }

  tbody > tr:hover {
    background-color: dimgray;
    cursor: pointer;
    color: white;
  }

  table thead th {
    vertical-align: bottom;
    border-bottom: 2px inset #F0C556;
  }
  
  th:focus {
    outline: rgb(240, 197, 86) solid 1px;
    border: 2px inset rgb(240, 197, 86);
  }

  .navbar {
    background-color: #232327
  }

  .darkInput {
    color: white;
    background-color: #1A1A1D;
  }

  .darkText {
    color: white
  }

  .noHover {
    pointer-events: none
  }

  .nav-link {
    color: gold;
  }
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const NavBarContainer = styled.div`
  width: 100%;
  height: 100%;

  ${props => !props.darkMode && `
    background-color: rgb(52, 58, 64);
    background-image: linear-gradient(0deg, rgb(127, 140, 141) 0%, rgb(52, 58, 64) 100%);
  `}

  ${props => props.darkMode && `
    background-color #232327;
  `}

`;

const ShowOnMobile = styled.div`
  @media (max-width: 1023px) {
    display: block;
  }
  display: none;
`;

const HideOnMobile = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
  display: block;
  width: 10%;
`;

export {
  PageWrapper,
  ContentWrapper,
  NavBarContainer,
  ShowOnMobile,
  HideOnMobile,
};
